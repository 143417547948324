import {
  DefaultOpeningDateState,
  scrollToPosition,
} from 'pages/tasks/requests/requests.config';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import TaskItem from './TaskItem';
import { TaskItemsProps } from './TaskItem.config';

const BASE_CLASS = 'adb-open-requests';

const TaskItems = ({ combinedRequests, referrerPath }: TaskItemsProps) => {
  const location = useLocation();
  const locationState = location.state as DefaultOpeningDateState;
  const idToBeExpanded = locationState?.idToBeExpanded ?? '';
  const [openTask, setOpenTask] = useState<string>(idToBeExpanded);
  const requestRefs = combinedRequests.reduce(
    (acc: Record<string, React.RefObject<HTMLDivElement>>, value) => {
      acc[value.id] = React.createRef();
      return acc;
    },
    {}
  );

  // When task is opened from dashboard, scroll into view
  useEffect(() => {
    if (idToBeExpanded && requestRefs[idToBeExpanded]) {
      setOpenTask(idToBeExpanded);
      scrollToPosition(requestRefs[idToBeExpanded]);
    }
  }, [idToBeExpanded, requestRefs]);

  return combinedRequests.map((task) => (
    <div
      ref={requestRefs[task.id]}
      key={task.id}
      className={`${BASE_CLASS}__items-wrapper`}
    >
      <TaskItem
        key={task.id}
        task={task}
        isOpen={openTask === task.id}
        referrerPath={referrerPath}
      />
    </div>
  ));
};
export default TaskItems;
