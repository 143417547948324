import { AppointmentType } from '@smart/adb-shared';
import { Text } from '@smart/react-components';
import {
  DateFormats,
  getLocaleDateFromString,
} from '@ui/library/helpers/date-locale';
import { getCustomerRoute } from '@utils/helpers/route';
import { useLanguageContext } from 'contexts/language-context';
import { AppointmentCard } from 'pages/tasks/config';
import { getIconFromType } from 'pages/tasks/requests/requests.config';
import { getIconFromStatus } from 'pages/tasks/tasks/utils';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import AdbLink from '../../atoms/AdbLink/AdbLink';
import AccordionItem from '../AccordionItem/AccordionItem';
import TestDriveDetail from './AppointmentDetail/TestDriveDetail';
import type { TaskItemProps } from './TaskItem.config';
import './TaskItem.scss';

const BASE_CLASS = 'adb-tasks';

const getIcon = (task: AppointmentCard, pathname: string) =>
  pathname === '/tasks/todos'
    ? getIconFromStatus(task.status)
    : getIconFromType(task.type as AppointmentType);

const TaskItem = ({ isOpen, referrerPath = '', task }: TaskItemProps) => {
  const { t } = useTranslation();
  const { locale } = useLanguageContext();
  const { pathname } = useLocation();

  const renderAppointment = () => {
    switch (task.type) {
      case AppointmentType.TestDrive:
        return (
          <TestDriveDetail appointment={task} referrerPath={referrerPath} />
        );
      default:
        return null;
    }
  };

  return (
    <AccordionItem
      itemId={task.id}
      isOpen={isOpen}
      hasDivider
      grid="5fr 3fr 2fr 4fr 2fr"
      icon={getIcon(task, pathname)}
      accordionDetail={renderAppointment()}
    >
      <Text variant="lbl-100" as="p">
        {t(task.title)}
      </Text>
      <Text variant="cap-300" as="p" className={`${BASE_CLASS}__header-date`}>
        {task.start &&
          `${t('task.start_date')}: ${getLocaleDateFromString(
            task.start,
            locale,
            DateFormats.DATE
          )}`}
      </Text>
      <Text variant="lbl-100" as="p">
        {task.status}
      </Text>
      <Text variant="lbl-100" as="p">
        <AdbLink
          state={{ key: referrerPath }}
          variant="hyperlink-200"
          title={[task.customer?.firstName, task.customer?.lastName].join(' ')}
          path={getCustomerRoute(task.customer)}
          additionalClasses={`${BASE_CLASS}__name-link`}
        />
      </Text>
      <Text variant="lbl-100" as="p">
        {'car' in task && task.car?.licensePlate}
      </Text>
    </AccordionItem>
  );
};
export default TaskItem;
