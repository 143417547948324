import { Text } from '@smart/react-components';
import { DynamicMediaPicture } from '@smart/web-components';
import { imageConfig1To1 } from '@utils/configs/image';
import { useTranslation } from 'react-i18next';
import { EmptyLayoutProps } from './EmptyLayout.config';
import './EmptyLayout.scss';

const EmptyLayout = ({ imgSrc, message }: EmptyLayoutProps) => {
  const { t } = useTranslation();

  return (
    <div className="adb-empty-screen">
      {imgSrc && (
        <DynamicMediaPicture
          imageUrl={imgSrc}
          imageAltText={t('general.other.empty_layout')}
          imageConfigs={imageConfig1To1}
        />
      )}
      <Text
        className="adb-empty-screen__heading"
        variant="hl-100"
        as="h1"
        data-testid="heading-component"
      >
        {message}
      </Text>
    </div>
  );
};

export default EmptyLayout;
